import React, { Suspense, useEffect } from 'react';
import './App.css';
import NavBar from './components/navigation/navigation-bar';
import Container from 'react-bootstrap/esm/Container';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'leaflet/dist/leaflet.css';
import HomePage from './pages/home/HomePage';
import Footer from './components/footer/Footer';
import PropertyViewPage from './pages/property-view/PropertyViewPage';
import BookmarkedPropertiesPage from './pages/bookmarks/BookmarkedPropertiesPage';
import RenterProfilePage from './pages/profile/Profile';
import MessagesPage from './pages/messages/MessagesPage';
import BrowseListingsPage from './pages/browse-listings/BrowseListingsPage';
import LandlordDashboard from './pages/landlord-dashboard/LandlordDashboard';
import CreateListingPage from './pages/create-listing/CreateListingPage';
import MyListingsPage from './pages/manage-listings/MyListingsPage';
import EditListingPage from './pages/edit-listing/EditListingPage';
import CookiePolicy from './pages/cookie-policy/CookiePolicy';
import ContactPage from './pages/contact/ContactPage';
import TermsOfService from './pages/terms-of-service/terms-of-service';
import LandlordGuide from './pages/landlord-guide/LandlordGuide';
import RenterGuide from './pages/renter-guide/RenterGuide';
import AboutUs from './pages/about-us/about-us';
import PrivacyPolicy from './pages/privacy-policy/privacy-policy';
import { Login } from './pages/login/login';
import { SignUp } from './pages/signup/signup';
import SuccessPage from './pages/success/SuccessPage';
import NotFoundPage from './pages/not-found-page/NotFoundPage';
import propertyToolsRoutes from './routes/propertyToolsRoutes';
import UserReviews from './components/user-reviews/UserReviews';
import ScrollToTop from './components/scroll-to-top/ScrollToTop';
import PropertyToolsGrid from './components/property/property-grid/PropertyToolsGrid';
import LandlordListingsPage from './pages/property-owner-listings/LandlordListingsPage';
import FAQPage from './pages/faqs/FAQPage';
import DataRequestPage from './pages/data-request/DataRequestPage';
import ProtectedRoute from './routes/ProtectedRoute';
import { remoteConfig } from './utility/firebaseRemoteConfigHelper';
import Blogs from './pages/blog/Blogs';
import BlogReadPage from './components/blog-read-page/BlogReadPage';
import { useCookieConsent } from './context/CookieConsentContext';
import CookieConsent from './components/cookie-consent/CookieConsent';
import PropertyListingLandingPage from './pages/property-listing-landing-page/PropertyListingLandingPage';
import ErrorBoundary from './components/error-boundary/ErrorBoundary';
import marketingRoutesWithSuspense from './routes/marketingRoutes';
import { Toaster } from 'react-hot-toast';
import CookiePreferences from './components/cookie-preferences/CookiePreferences';
import NewsletterConfirmation from './pages/newsletter-confirmation/NewsletterConfirmation';
import NewsletterUnsubscribe from './pages/newsletter-unsubscribe/NewsletterUnsubscribe';
import EmailVerification from './pages/email-verification/EmailVerification';
import SafetyPage from './pages/safety-page/SafetyPage';
import ReportListingPage from './pages/report-listing/ReportListingPage';
import HelpCenterPage from './pages/help-center/HelpCenterPage';
import OnboardingPage from './pages/onboarding-page/OnboardingPage';
import AutoApplyDashboard from './components/auto-apply/AutoApplyDashboard';
import AutoApplyFAQ from './components/auto-apply/AutoApplyFAQ';
import HowAutoApplyWorks from './components/auto-apply/HowAutoApplyWorks';
import SuccessStories from './components/auto-apply/SuccessStories';
import AdminVerificationDashboard from './pages/admin-verification-dashboard/AdminVerificationDashboard';
import EmailTemplateForm from './pages/test-email-templates/EmailTemplateForm';
import TesCom from './components/etest-component/TesCom';
import CardCarousel from './components/etest-component/TesCom';

const App = () => {
  const { consentStatus, updateConsentStatus, isConsentFormVisible } =
    useCookieConsent();

  const LoadingFallback = () => (
    <div className="flex justify-center items-center min-h-[400px]">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
    </div>
  );

  const handleAcceptCookies = () => {
    updateConsentStatus('accepted');
    // Initialize cookies or tracking here
  };

  const handleRejectCookies = () => {
    updateConsentStatus('rejected');
    // Disable cookies or tracking here
  };

  useEffect(() => {
    const initializeConfig = async () => {
      // The remoteConfig instance is already created and initialized when imported,
      // but we can add an extra check here if needed
      await remoteConfig.initializeRemoteConfig();
    };

    initializeConfig();
  }, []);

  return (
    <>
      {(consentStatus === null || isConsentFormVisible) && (
        <CookieConsent
          onAccept={handleAcceptCookies}
          onReject={handleRejectCookies}
        />
      )}
      <BrowserRouter>
        <ScrollToTop />
        <NavBar />
        <Container>
          <ErrorBoundary>
            <Suspense fallback={<LoadingFallback />}>
              <Toaster position="top-right" />
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/browse" element={<BrowseListingsPage />} />
                <Route path="/onboarding" element={<OnboardingPage />} />
                <Route
                  path="/cookie-preferences"
                  element={<CookiePreferences />}
                />
                <Route path="/verify-email" element={<EmailVerification />} />
                {/* Newsletter routes */}
                <Route
                  path="/confirm-newsletter"
                  element={<NewsletterConfirmation />}
                />

                {/* Safety & Support Routes */}
                <Route path="/safety" element={<SafetyPage />} />
                <Route path="/report-listing" element={<ReportListingPage />} />
                <Route path="/help-center" element={<HelpCenterPage />} />

                <Route
                  path="/unsubscribe-newsletter"
                  element={<NewsletterUnsubscribe />}
                />
                <Route
                  path="/list-your-property"
                  element={<PropertyListingLandingPage />}
                />
                <Route path="/blog" element={<Blogs />} />
                <Route path="/blog/:id" element={<BlogReadPage />} />
                <Route
                  path="/profile"
                  element={
                    <ProtectedRoute>
                      <RenterProfilePage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/messages"
                  element={
                    <ProtectedRoute>
                      <MessagesPage />
                    </ProtectedRoute>
                  }
                />

                <Route path="/auto-apply" element={<AutoApplyDashboard />} />
                <Route
                  path="/auto-apply/how-it-works"
                  element={<HowAutoApplyWorks />}
                />
                <Route
                  path="/auto-apply/success-stories"
                  element={<SuccessStories />}
                />
                <Route path="/auto-apply/faq" element={<AutoApplyFAQ />} />
                <Route
                  path="/messages/:id"
                  element={
                    <ProtectedRoute>
                      <MessagesPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin-dashboard"
                  element={
                    <ProtectedRoute>
                      <AdminVerificationDashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <ProtectedRoute>
                      <LandlordDashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/test-email-templates"
                  element={
                    <ProtectedRoute>
                      <EmailTemplateForm />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/add-listing"
                  element={
                    <ProtectedRoute>
                      <CreateListingPage />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/listing-success"
                  element={
                    <ProtectedRoute>
                      <SuccessPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/property-owner-listings/:owner"
                  element={<LandlordListingsPage />}
                />
                <Route path="/property-tools" element={<PropertyToolsGrid />} />
                <Route path="/cookie-policy" element={<CookiePolicy />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/testcom" element={<CardCarousel />} />
                <Route path="/landlord-guide" element={<LandlordGuide />} />
                <Route path="/renter-guide" element={<RenterGuide />} />
                <Route path="/feedback" element={<UserReviews />} />
                <Route path="/faqs" element={<FAQPage />} />
                <Route path="/data-requests" element={<DataRequestPage />} />
                <Route
                  path="/edit-listing/:id"
                  element={
                    <ProtectedRoute>
                      <EditListingPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/my-listings"
                  element={
                    <ProtectedRoute>
                      <MyListingsPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/bookmarks"
                  element={<BookmarkedPropertiesPage />}
                />
                <Route
                  path="/property/:listingId"
                  element={<PropertyViewPage />}
                />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/terms-of-service" element={<TermsOfService />} />

                {/* Dynamically Generated Property Utility Tools Routes */}
                {propertyToolsRoutes.map((route) => (
                  <Route
                    key={route.path}
                    path={`/property-tools/${route.path}`}
                    element={<route.component />}
                  />
                ))}

                {/* Marketing-specific routes with SEO */}
                {/* Dynamically map marketing routes */}
                {marketingRoutesWithSuspense.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.element}
                  />
                ))}

                {/* 404 Not Found Route */}
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </Suspense>
          </ErrorBoundary>
        </Container>
        <Footer />
      </BrowserRouter>
    </>
  );
};

export default App;
