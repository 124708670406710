import React, { useState, useEffect, FormEvent } from 'react';
import { useAuth } from '../../auth/firebaseAuth';

interface TemplateField {
  id: string;
  label: string;
  type:
    | 'text'
    | 'email'
    | 'textarea'
    | 'select'
    | 'datetime-local'
    | 'date'
    | 'url'
    | 'number';
  options?: { value: string; label: string }[];
  placeholder?: string;
  conditional?: string;
  conditionalValue?: string;
  value?: string | number;
}

interface TemplateFields {
  [key: string]: TemplateField[];
}

const EmailTemplateForm: React.FC = () => {
  const { userProfile } = useAuth();
  const [template, setTemplate] = useState<string>('welcome');
  const [fields, setFields] = useState<TemplateField[]>([]);
  const [formData, setFormData] = useState<Record<string, string>>({});
  const [result, setResult] = useState<{
    success: boolean;
    message: string;
  } | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const templateFields: TemplateFields = {
    welcome: [],
    newEnquiry: [
      { id: 'propertyTitle', label: 'Property Title', type: 'text' },
      { id: 'propertyLocation', label: 'Property Location', type: 'text' },
      { id: 'ownerName', label: 'Owner Name', type: 'text' },
      { id: 'senderName', label: 'Sender Name', type: 'text' },
      { id: 'senderEmail', label: 'Sender Email', type: 'email' },
      { id: 'content', label: 'Message Content', type: 'textarea' }
    ],
    listingPendingReview: [
      { id: 'ownerName', label: 'Owner Name', type: 'text' },
      { id: 'propertyTitle', label: 'Property Title', type: 'text' },
      { id: 'propertyLocation', label: 'Property Location', type: 'text' }
    ],
    listingLive: [
      { id: 'ownerName', label: 'Owner Name', type: 'text' },
      { id: 'propertyTitle', label: 'Property Title', type: 'text' },
      { id: 'propertyLocation', label: 'Property Location', type: 'text' }
    ],
    newMessage: [
      { id: 'recipientName', label: 'Recipient Name', type: 'text' },
      { id: 'senderName', label: 'Sender Name', type: 'text' },
      { id: 'propertyTitle', label: 'Property Title', type: 'text' },
      { id: 'messagePreview', label: 'Message Preview', type: 'textarea' }
    ],
    applicationConfirmation: [
      { id: 'senderName', label: 'Sender Name', type: 'text' },
      { id: 'recipientName', label: 'Property Owner Name', type: 'text' },
      { id: 'propertyTitle', label: 'Property Title', type: 'text' },
      { id: 'propertyLocation', label: 'Property Location', type: 'text' },
      { id: 'messageContent', label: 'Message Content', type: 'textarea' }
    ],
    inquiryConfirmation: [
      { id: 'senderName', label: 'Sender Name', type: 'text' },
      { id: 'senderEmail', label: 'Sender Email', type: 'email' },
      { id: 'ownerName', label: 'Property Owner Name', type: 'text' },
      { id: 'propertyTitle', label: 'Property Title', type: 'text' },
      { id: 'propertyLocation', label: 'Property Location', type: 'text' },
      { id: 'content', label: 'Inquiry Message', type: 'textarea' }
    ],
    newsletterConfirmation: [
      { id: 'name', label: 'Subscriber Name', type: 'text' },
      { id: 'email', label: 'Subscriber Email', type: 'email' },
      {
        id: 'confirmation_link',
        label: 'Confirmation Link',
        type: 'url',
        value: 'https://rentsmart.ie/confirm-newsletter?token=test&id=test'
      }
    ],
    accountDeactivation: [
      { id: 'name', label: 'User Name', type: 'text' },
      { id: 'accountId', label: 'Account ID', type: 'text' },
      {
        id: 'deactivationDate',
        label: 'Deactivation Date',
        type: 'datetime-local',
        value: new Date().toISOString().slice(0, 16)
      }
    ],
    accountDeletion: [
      { id: 'name', label: 'User Name', type: 'text' },
      { id: 'accountId', label: 'Account ID', type: 'text' },
      {
        id: 'deletionDate',
        label: 'Deletion Date',
        type: 'datetime-local',
        value: new Date().toISOString().slice(0, 16)
      }
    ],
    autoApplyNotification: [
      { id: 'propertyTitle', label: 'Property Title', type: 'text' },
      { id: 'propertyLocation', label: 'Property Location', type: 'text' },
      {
        id: 'propertyPrice',
        label: 'Property Price',
        type: 'number',
        placeholder: '1500'
      },
      {
        id: 'applicationDate',
        label: 'Application Date',
        type: 'datetime-local',
        value: new Date().toISOString().slice(0, 16)
      },
      {
        id: 'expiryDate',
        label: 'Auto Apply Expiry Date',
        type: 'date',
        value: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 10)
      }
    ],
    identityVerificationSubmitted: [
      { id: 'name', label: 'User Name', type: 'text' },
      {
        id: 'documentType',
        label: 'Document Type',
        type: 'select',
        options: [
          { value: 'passport', label: 'Passport' },
          { value: 'driverLicense', label: "Driver's License" },
          { value: 'nationalId', label: 'National ID' }
        ]
      },
      {
        id: 'submissionDate',
        label: 'Submission Date',
        type: 'datetime-local',
        value: new Date().toISOString().slice(0, 16)
      }
    ],

    identityVerificationApproved: [
      { id: 'name', label: 'User Name', type: 'text' },
      {
        id: 'documentType',
        label: 'Document Type',
        type: 'select',
        options: [
          { value: 'passport', label: 'Passport' },
          { value: 'driverLicense', label: "Driver's License" },
          { value: 'nationalId', label: 'National ID' }
        ]
      },
      {
        id: 'approvalDate',
        label: 'Approval Date',
        type: 'datetime-local',
        value: new Date().toISOString().slice(0, 16)
      }
    ],

    identityVerificationRejected: [
      { id: 'name', label: 'User Name', type: 'text' },
      {
        id: 'documentType',
        label: 'Document Type',
        type: 'select',
        options: [
          { value: 'passport', label: 'Passport' },
          { value: 'driverLicense', label: "Driver's License" },
          { value: 'nationalId', label: 'National ID' }
        ]
      },
      {
        id: 'submissionDate',
        label: 'Submission Date',
        type: 'datetime-local',
        value: new Date().toISOString().slice(0, 16)
      },
      {
        id: 'reviewDate',
        label: 'Review Date',
        type: 'datetime-local',
        value: new Date().toISOString().slice(0, 16)
      },
      {
        id: 'rejectionReason',
        label: 'Rejection Reason',
        type: 'select',
        options: [
          {
            value: 'document_unclear',
            label: 'Document is unclear or unreadable'
          },
          { value: 'document_expired', label: 'Document has expired' },
          {
            value: 'information_mismatch',
            label: 'Information does not match our records'
          },
          {
            value: 'document_incomplete',
            label: 'Document is incomplete or missing pages'
          },
          {
            value: 'document_invalid',
            label: 'Document type is not accepted'
          },
          {
            value: 'quality_issues',
            label: 'Image quality issues (glare/shadows)'
          },
          { value: 'custom', label: 'Other (Custom Reason)' }
        ]
      },
      {
        id: 'customRejectionReason',
        label: 'Custom Rejection Reason',
        type: 'textarea',
        placeholder: 'Enter custom rejection reason...',
        conditional: 'rejectionReason',
        conditionalValue: 'custom'
      }
    ],
    identityVerificationTeamNotification: [
      { id: 'userName', label: 'User Name', type: 'text' },
      { id: 'userEmail', label: 'User Email', type: 'email' },
      {
        id: 'documentType',
        label: 'Document Type',
        type: 'select',
        options: [
          { value: 'passport', label: 'Passport' },
          { value: 'driverLicense', label: "Driver's License" },
          { value: 'nationalId', label: 'National ID' }
        ]
      },
      { id: 'documentNumber', label: 'Document Number', type: 'text' },
      {
        id: 'submissionDate',
        label: 'Submission Date',
        type: 'datetime-local',
        value: new Date().toISOString().slice(0, 16)
      }
    ]
  };

  useEffect(() => {
    setFields(templateFields[template] || []);
  }, [template]);

  const handleFieldChange = (id: string, value: string) => {
    setFormData((prev) => ({
      ...prev,
      [id]: value
    }));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    setResult(null); // Clear any previous results

    try {
      const templateType = template;
      const fields = templateFields[templateType] || [];
      const templateData: Record<string, string> = {};

      // Collect basic fields
      templateData.email = formData.email || '';
      templateData.name = formData.name || '';

      // Collect template-specific fields
      fields.forEach((field) => {
        const fieldValue = formData[field.id] || '';
        if (field.id === 'rejectionReason') {
          const reasonValue = formData[field.id] || '';
          templateData[field.id] =
            reasonValue === 'custom'
              ? formData['customRejectionReason'] || ''
              : reasonValue;
        } else if (!field.conditional) {
          templateData[field.id] = fieldValue;
        }
      });

      // Format dates nicely for display in email
      const formatDate = (dateString: string) =>
        new Date(dateString).toLocaleString('en-IE', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        });

      if (templateData.submissionDate) {
        templateData.submissionDate = formatDate(templateData.submissionDate);
      }
      if (templateData.approvalDate) {
        templateData.approvalDate = formatDate(templateData.approvalDate);
      }
      if (templateData.reviewDate) {
        templateData.reviewDate = formatDate(templateData.reviewDate);
      }

      // Prepare the payload
      const payload = {
        email: templateData.email,
        name: templateData.name,
        template: templateType,
        sender: formData.sender || 'support@rentsmart.ie',
        data: templateData
      };

      console.log('Payload to send:', payload);

      // Make the POST request
      const response = await fetch(
        'https://testwelcomeemail-byx4ztggoq-ew.a.run.app',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        }
      );

      const responseData = await response.json();

      // Handle the result
      if (response.ok) {
        setResult({
          success: true,
          message: `Request ID: ${responseData.requestId}`
        });
      } else {
        setResult({
          success: false,
          message: responseData.error || 'Unknown error occurred'
        });
      }
    } catch (error) {
      setResult({
        success: false,
        message: (error as Error).message
      });
    } finally {
      setIsSubmitting(false); // Re-enable the button
    }
  };

  // Access control check
  if (!userProfile?.isPlatformAdmin) {
    return (
      <div className="max-w-2xl mx-auto mt-8 bg-red-50 border-l-4 border-red-500 p-4">
        <p className="text-red-700">
          You do not have permission to access this page. Please contact an
          administrator if you believe this is an error.
        </p>
      </div>
    );
  }

  return (
    <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-md overflow-hidden p-6 mt-4">
      <h1 className="text-2xl font-bold mb-6">Test Email Templates</h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Template:
          </label>
          <select
            value={template}
            onChange={(e) => setTemplate(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          >
            {Object.keys(templateFields).map((key) => (
              <option key={key} value={key}>
                {key}
              </option>
            ))}
          </select>
        </div>

        <div id="basicFields">
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Email:
            </label>
            <input
              type="email"
              required
              value={formData.email || ''}
              onChange={(e) => handleFieldChange('email', e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Name:
            </label>
            <input
              type="text"
              value={formData.name || ''}
              onChange={(e) => handleFieldChange('name', e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
        </div>

        <div id="templateFields" className="space-y-4">
          {fields.map((field) => (
            <div key={field.id} className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                {field.label}:
              </label>
              {field.type === 'textarea' ? (
                <textarea
                  rows={3}
                  value={formData[field.id] || ''}
                  onChange={(e) => handleFieldChange(field.id, e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              ) : field.type === 'select' && field.options ? (
                <select
                  value={formData[field.id] || ''}
                  onChange={(e) => handleFieldChange(field.id, e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                >
                  {field.options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  type={field.type}
                  value={formData[field.id] || ''}
                  onChange={(e) => handleFieldChange(field.id, e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              )}
            </div>
          ))}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Sender:
          </label>
          <select
            value={formData.sender || ''}
            onChange={(e) => handleFieldChange('sender', e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          >
            <option value="support@rentsmart.ie">Support</option>
            <option value="noreply@rentsmart.ie">No Reply</option>
            <option value="info@rentsmart.ie">Info</option>
          </select>
        </div>

        <button
          type="submit"
          disabled={isSubmitting}
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          {isSubmitting ? 'Sending...' : 'Send Test Email'}
        </button>
      </form>

      {result && (
        <div
          className={`mt-4 p-4 rounded-md ${
            result.success
              ? 'bg-green-50 text-green-800'
              : 'bg-red-50 text-red-800'
          }`}
        >
          <p className="font-medium">{result.success ? 'Success!' : 'Error'}</p>
          <p className="text-sm">{result.message}</p>
        </div>
      )}
    </div>
  );
};

export default EmailTemplateForm;
