import React from 'react';
import {
  Card,
  Button,
  makeStyles,
  tokens,
  CardHeader,
  CardFooter,
  CardPreview,
  Text
} from '@fluentui/react-components';
import {
  Carousel,
  CarouselCard,
  CarouselNav,
  CarouselNavButton,
  CarouselNavContainer,
  CarouselViewport,
  CarouselSlider
} from '@fluentui/react-components';

const useStyles = makeStyles({
  root: {
    maxWidth: '288px',
    height: '500px',
    margin: 0,
    padding: 0
  },
  sampleCarouselCard: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    backgroundColor: tokens.colorNeutralBackground1,
    margin: 0,
    padding: 0
  },
  cardHeader: {
    marginBottom: 0,
    padding: '12px'
  },
  text: {
    margin: 0,
    marginBottom: 0,
    padding: '12px'
  },
  cardFooter: {
    marginTop: 'auto',
    padding: '12px'
  }
});

interface CardData {
  header: string;
  text1: string;
  text2: string;
  imageSrc: string;
  onClick: () => void;
}

const cardDataArray: CardData[] = [
  {
    header: 'Card Header 1',
    text1:
      'Donut chocolate bar oat cake. Dragée tiramisu lollipop bear claw. Marshmallow pastry jujubes toffee sugar plum.',
    text2:
      'Lollipop pastry chupa chups wafer. Cake chocolate bar cotton candy jelly beans dessert.',
    imageSrc: 'https://via.placeholder.com/400x200',
    onClick: () => alert('Opened Classroom Collaboration app 1')
  },
  {
    header: 'Card Header 2',
    text1:
      'Candy canes marshmallow muffin gingerbread. Bonbon powder wafer jelly beans candy canes.',
    text2:
      'Chocolate cake biscuit jelly beans soufflé. Cheesecake jelly beans candy canes pastry.',
    imageSrc: 'https://via.placeholder.com/400x200',
    onClick: () => alert('Opened Classroom Collaboration app 2')
  },
  {
    header: 'Card Header 3',
    text1:
      'Fruitcake donut chocolate bar pudding lollipop bear claw pie dragée. Jelly beans wafer chocolate bar cake.',
    text2:
      'Marzipan candy cheesecake brownie halvah jelly beans. Macaroon chocolate bar toffee tart.',
    imageSrc: 'https://via.placeholder.com/400x200',
    onClick: () => alert('Opened Classroom Collaboration app 3')
  },
  {
    header: 'Card Header 4',
    text1:
      'Cupcake ipsum dolor sit amet. Tiramisu brownie gummies chocolate bar cake lemon drops.',
    text2:
      'Cookie ice cream cheesecake croissant. Brownie muffin jelly beans sweet roll.',
    imageSrc: 'https://via.placeholder.com/400x200',
    onClick: () => alert('Opened Classroom Collaboration app 4')
  }
];

interface SampleCardProps {
  data: CardData;
  index: number;
  total: number;
}

const SampleCardWithAction: React.FC<SampleCardProps> = ({
  data,
  index,
  total
}) => {
  const styles = useStyles();
  const { header, text1, text2, imageSrc, onClick } = data;

  return (
    <CarouselCard
      className={styles.sampleCarouselCard}
      aria-label={`${index + 1} of ${total}`}
      id={`carousel-item-${index}`}
      onClick={onClick}
    >
      <CardPreview>
        <img
          src={imageSrc}
          alt="Sample Preview"
          style={{ display: 'block', width: '100%' }}
        />
      </CardPreview>

      <CardHeader
        className={styles.cardHeader}
        header={
          <Text weight="semibold" size={500}>
            {header}
          </Text>
        }
      />

      <p className={styles.text}>{text1}</p>

      <p className={styles.text}>{text2}</p>

      <CardFooter className={styles.cardFooter}>
        <Button appearance="secondary" onClick={onClick}>
          See plans
        </Button>
      </CardFooter>
    </CarouselCard>
  );
};

const getAnnouncement = (index: number, totalSlides: number): string =>
  `Carousel slide ${index + 1} of ${totalSlides}`;

const CardWithCarousel: React.FC = () => {
  const styles = useStyles();
  const totalItems = cardDataArray.length;

  return (
    <Card className={styles.root}>
      <Carousel
        groupSize={1}
        circular
        announcement={getAnnouncement}
      >
        <CarouselViewport>
          <CarouselSlider>
            {cardDataArray.map((data, index) => (
              <SampleCardWithAction
                key={index}
                data={data}
                index={index}
                total={totalItems}
              />
            ))}
          </CarouselSlider>
        </CarouselViewport>
        <CarouselNavContainer
          layout="inline"
          autoplay={{ 'aria-label': 'Enable autoplay' }}
          next={{ 'aria-label': 'Next slide' }}
          prev={{ 'aria-label': 'Previous slide' }}
        >
          <CarouselNav>
            {(navIndex: number) => (
              <CarouselNavButton aria-label={`Go to slide ${navIndex + 1}`} />
            )}
          </CarouselNav>
        </CarouselNavContainer>
      </Carousel>
    </Card>
  );
};

export default CardWithCarousel;
