import React from 'react';
import {
  PlusCircleIcon,
  ListBulletIcon,
  EnvelopeIcon,
  BuildingOfficeIcon,
  ClipboardDocumentCheckIcon,
  ChatBubbleLeftRightIcon
} from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { remoteConfig } from '../../utility/firebaseRemoteConfigHelper';

interface DashboardOverviewProps {
  isVerified: boolean;
  landlordName: string;
  totalListings: number;
  activeListings: number;
  pendingInquiries: number;
}

const DashboardOverview: React.FC<DashboardOverviewProps> = ({
  isVerified,
  landlordName,
  totalListings,
  activeListings,
  pendingInquiries
}) => {
  const navigate = useNavigate();
  const greetingPrefix = 'Welcome back';
  const userGreeting = landlordName.length
    ? `${greetingPrefix}, ${landlordName}!`
    : greetingPrefix;
  return (
    <div className="bg-gradient-to-r from-blue-50 to-indigo-50 p-8 rounded-xl shadow-lg">
      <h2 className="text-3xl font-extrabold mb-6 text-gray-800">
        {userGreeting}
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        {/* Total Listings Card */}
        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-shadow duration-300">
          <div className="flex items-center">
            <BuildingOfficeIcon className="w-12 h-12 text-blue-500 mr-4" />
            <div>
              <h3 className="text-xl font-semibold text-gray-700">
                Total Listings
              </h3>
              <p className="text-4xl font-bold text-gray-900">
                {totalListings}
              </p>
            </div>
          </div>
        </div>
        {/* Active Listings Card */}
        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-shadow duration-300">
          <div className="flex items-center">
            <ClipboardDocumentCheckIcon className="w-12 h-12 text-green-500 mr-4" />
            <div>
              <h3 className="text-xl font-semibold text-gray-700">
                Active Listings
              </h3>
              <p className="text-4xl font-bold text-gray-900">
                {activeListings}
              </p>
            </div>
          </div>
        </div>
        {/* Pending Inquiries Card */}
        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-shadow duration-300">
          <div className="flex items-center">
            <ChatBubbleLeftRightIcon className="w-12 h-12 text-yellow-500 mr-4" />
            <div>
              <h3 className="text-xl font-semibold text-gray-700">
                Pending Inquiries
              </h3>
              <p className="text-4xl font-bold text-gray-900">
                {pendingInquiries}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap gap-4 mt-8">
        {/* {!isVerified &&
          (remoteConfig.requireIdentityVerification() ||
            remoteConfig.requireEmailVerificationToCreateListing()) && (
            <>
              <p>
                You need to complete email or identity verification before you
                can create listings
              </p>
            </>
          )} */}

        <>
          <button
            onClick={() => navigate('/add-listing')}
            className="flex items-center bg-gradient-to-r from-blue-500 to-indigo-500 text-white px-6 py-3 rounded-lg hover:shadow-lg transform hover:-translate-y-1 transition-all duration-300"
          >
            <PlusCircleIcon className="w-6 h-6 mr-2" />
            Add New Listing
          </button>
          <button
            onClick={() => navigate('/my-listings')}
            className="flex items-center bg-gradient-to-r from-green-500 to-teal-500 text-white px-6 py-3 rounded-lg hover:shadow-lg transform hover:-translate-y-1 transition-all duration-300"
          >
            <ListBulletIcon className="w-6 h-6 mr-2" />
            View All Listings
          </button>
          <button
            onClick={() => navigate('/messages')}
            className="flex items-center bg-gradient-to-r from-yellow-500 to-orange-500 text-white px-6 py-3 rounded-lg hover:shadow-lg transform hover:-translate-y-1 transition-all duration-300"
          >
            <EnvelopeIcon className="w-6 h-6 mr-2" />
            Check Messages
          </button>
        </>
        {/* {isVerified && remoteConfig.requireEmailVerificationToCreateListing() && (
          <>
            <button
              onClick={() => navigate('/add-listing')}
              className="flex items-center bg-gradient-to-r from-blue-500 to-indigo-500 text-white px-6 py-3 rounded-lg hover:shadow-lg transform hover:-translate-y-1 transition-all duration-300"
            >
              <PlusCircleIcon className="w-6 h-6 mr-2" />
              Add New Listing
            </button>
            <button
              onClick={() => navigate('/my-listings')}
              className="flex items-center bg-gradient-to-r from-green-500 to-teal-500 text-white px-6 py-3 rounded-lg hover:shadow-lg transform hover:-translate-y-1 transition-all duration-300"
            >
              <ListBulletIcon className="w-6 h-6 mr-2" />
              View All Listings
            </button>
            <button
              onClick={() => navigate('/messages')}
              className="flex items-center bg-gradient-to-r from-yellow-500 to-orange-500 text-white px-6 py-3 rounded-lg hover:shadow-lg transform hover:-translate-y-1 transition-all duration-300"
            >
              <EnvelopeIcon className="w-6 h-6 mr-2" />
              Check Messages
            </button>
          </>
        )} */}
      </div>
    </div>
  );
};

export default DashboardOverview;
