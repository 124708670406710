import React from 'react';

const FAQPage: React.FC = () => {
  const faqData = [
    {
      question: 'What is RentSmart.ie?',
      answer:
        'RentSmart.ie is a platform founded in 2024 to simplify the rental experience in Ireland, making it easier, more transparent, and efficient for both tenants and landlords. Our goal is to connect quality tenants with responsible landlords.'
    },
    {
      question: 'What is RentSmart.ie’s mission?',
      answer:
        'Our mission is to "create a seamless, trustworthy platform that connects quality tenants with responsible landlords, fostering better rental experiences across Ireland."'
    },
    {
      question: 'What values does RentSmart.ie prioritize?',
      answer:
        'Our core values are community, fairness, innovation, and trust. We believe in building positive rental communities, ensuring transparency, continuously innovating, and prioritizing safety on our platform.'
    },
    {
      question: 'How can I join the RentSmart.ie team?',
      answer:
        "We're always looking for talented individuals who are passionate about improving the rental experience in Ireland. Check out our Careers page or email us at careers@rentsmart.ie for more information."
    },
    {
      question: 'What should I do before listing my property on RentSmart.ie?',
      answer:
        'Ensure your property is clean, in good repair, and ready for tenants. Address any maintenance issues, update fixtures if needed, ensure all safety measures are in place, and take high-quality photos for your listing.'
    },
    {
      question: 'How should I set the right rent for my property?',
      answer:
        'Research similar properties in the area to understand market rates, consider your property’s features and condition, and factor in your costs. Be aware of any rent control regulations in your area.'
    },
    {
      question: 'What legal responsibilities do landlords have in Ireland?',
      answer:
        'Landlords must register tenancies with the RTB, provide a safe property, respect tenant privacy, provide rent receipts, return the security deposit (minus any legitimate deductions), and follow proper procedures for rent increases and evictions. Visit the Residential Tenancies Board website for more information.'
    },
    {
      question: 'What tips can help me manage my property successfully?',
      answer:
        'Screen tenants thoroughly, use a comprehensive lease agreement, conduct regular inspections, address maintenance issues promptly, communicate openly with tenants, and keep detailed records. Consider hiring a property manager if you own multiple properties.'
    },
    {
      question: 'What should I consider when finding a rental property?',
      answer:
        'Determine your budget, prioritize location, make a list of must-have amenities, read property descriptions carefully, and schedule viewings for properties that meet your criteria.'
    },
    {
      question: 'What should I know about lease agreements?',
      answer:
        'Understand key terms such as rent amount, due date, security deposit conditions, lease length, rules on pets and guests, and maintenance responsibilities. Knowing these details can help you avoid future misunderstandings.'
    },
    {
      question: 'What rights do tenants have in Ireland?',
      answer:
        'Tenants have the right to a safe and well-maintained property, privacy, protection against unfair eviction and rent increases, and the return of their security deposit. Visit the RTB website for detailed information on tenant rights.'
    },
    {
      question: 'What tips can help me maintain a successful tenancy?',
      answer:
        'Pay rent on time, keep the property in good condition, report maintenance issues, be considerate of neighbors, communicate with your landlord, and consider renter’s insurance to protect your belongings.'
    }
  ];

  return (
    <div className="container mx-auto py-12 px-6">
      <h1 className="text-4xl font-bold text-gray-800 mb-8">
        Frequently Asked Questions
      </h1>
      <div className="space-y-6">
        {faqData.map((faq, index) => (
          <div
            key={index}
            className="bg-white shadow-md rounded-lg p-6 border border-gray-200"
          >
            <h2 className="text-xl font-semibold text-blue-600">
              {faq.question}
            </h2>
            <p className="text-gray-700 mt-2">{faq.answer}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQPage;
