import React, { useEffect, useState } from 'react';
import { useAuth } from '../../auth/firebaseAuth';
import { createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useNavigate, Link } from 'react-router-dom';
import { auth } from '../../auth/firebaseConfig';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { serverTimestamp } from 'firebase/firestore';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { insertDocument } from '../../utility/firebaseHelpers';
import { UserPlus } from 'lucide-react';
import { BaseUser } from '../../types/userTypes';
import googleLogoSvg from '../../../src/media/logo/svgs/google.svg';
interface SignUpData {
  email: string;
  password: string;
  confirmPassword: string;
  name: string;
  phone: string;
  role: 'landlord' | 'renter' | '';
}

interface PasswordRequirement {
  id: string;
  label: string;
  regex: RegExp;
  validator?: (password: string) => boolean;
}

export const SignUp: React.FC = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [step, setStep] = useState(1);
  const [signUpData, setSignUpData] = useState<SignUpData>({
    email: '',
    password: '',
    confirmPassword: '',
    name: '',
    phone: '',
    role: ''
  });
  const [passwordRequirements, setPasswordRequirements] = useState<
    PasswordRequirement[]
  >([
    {
      id: 'length',
      label: 'Be at least 8 characters long',
      validator: (password: string) => password.length >= 8,
      regex: /.*/
    },
    {
      id: 'uppercase',
      label: 'Contain at least one uppercase letter',
      regex: /[A-Z]/
    },
    {
      id: 'lowercase',
      label: 'Contain at least one lowercase letter',
      regex: /[a-z]/
    },
    {
      id: 'number',
      label: 'Contain at least one number',
      regex: /\d/
    },
    {
      id: 'special',
      label: 'Contain at least one special character',
      regex: /[!@#$%^&*(),.?":{}|<>]/
    },
    {
      id: 'spaces',
      label: 'Not contain spaces',
      validator: (password: string) => !password.includes(' '),
      regex: /.*/
    }
  ]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);

  useEffect(() => {
    const quickSignupData = localStorage.getItem('quickSignupData');
    if (quickSignupData) {
      const data = JSON.parse(quickSignupData);
      if (new Date().getTime() - new Date(data.timestamp).getTime() < 3600000) {
        // 1 hour
        setSignUpData((prev) => ({
          ...prev,
          name: data.m_name,
          email: data.email
        }));
      }
      localStorage.removeItem('quickSignupData');
    }
  }, []);

  const checkPasswordRequirement = (
    requirement: PasswordRequirement,
    password: string
  ): boolean => {
    if (requirement.validator) {
      return requirement.validator(password);
    }
    return requirement.regex.test(password);
  };

  const validatePassword = (
    password: string
  ): { isValid: boolean; error: string } => {
    const failedRequirements = passwordRequirements.filter(
      (requirement) => !checkPasswordRequirement(requirement, password)
    );

    if (failedRequirements.length > 0) {
      return {
        isValid: false,
        error: `Password must: ${failedRequirements[0].label.toLowerCase()}`
      };
    }

    return { isValid: true, error: '' };
  };

  const validateStep1 = () => {
    if (
      !signUpData.email ||
      !signUpData.password ||
      !signUpData.confirmPassword
    ) {
      setError('Please fill in all fields.');
      return false;
    }

    if (!/\S+@\S+\.\S+/.test(signUpData.email)) {
      setError('Please enter a valid email address.');
      return false;
    }

    const passwordValidation = validatePassword(signUpData.password);
    if (!passwordValidation.isValid) {
      setError(passwordValidation.error);
      return false;
    }

    if (signUpData.password !== signUpData.confirmPassword) {
      setError('Passwords do not match.');
      return false;
    }

    return true;
  };

  const validateStep2 = () => {
    if (!signUpData.name || !signUpData.phone) {
      setError('Please enter your full name and phone number.');
      return false;
    }
    if (!signUpData.role) {
      setError('Please select whether you want to rent or list a property.');
      return false;
    }
    return true;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSignUpData((prev) => ({ ...prev, [name]: value }));
  };

  const handleNextStep = () => {
    if (validateStep1()) {
      setStep(2);
      setError('');
    }
  };

  const handlePrevStep = () => {
    setStep(1);
    setError('');
  };

  const createUser = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateStep2()) return;
    if (!agreeToTerms) {
      setError('You must agree to the Terms of Service to create an account.');
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        signUpData.email,
        signUpData.password
      );

      const userDefaultBio =
        signUpData.role === 'landlord'
          ? 'I have a property available for rent or sale.'
          : 'I am looking for a place to rent.';
      const userData: BaseUser = {
        id: userCredential.user.uid,
        name: signUpData.name,
        email: signUpData.email,
        phone: signUpData.phone,
        accountVerification: {
          emailVerified: false,
          phoneVerified: false,
          identityVerified: false
        },
        accountCreationDate: serverTimestamp(),
        bio: userDefaultBio,
        communicationPreferences: {
          emailOffers: false,
          pushNotifications: false
        },
        listingPreferences: {
          showEmail: false,
          showMobile: false
        },
        role: signUpData.role || 'renter'
      };

      await insertDocument('users', userData, userCredential.user.uid);
      if (signUpData.role === 'landlord') {
        navigate('/onboarding');
      } else {
        navigate('/browse');
      }
    } catch (error) {
      setError('Failed to create an account. Please try again.');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

   const handleGoogleSignUp = async () => {
     setIsLoading(true);
     setError('');

     try {
       const provider = new GoogleAuthProvider();
       const userCredential = await signInWithPopup(auth, provider);

       // Create user account in users collection
       const userData: BaseUser = {
         id: userCredential.user.uid,
         name: userCredential.user.displayName ?? 'User',
         email: userCredential.user.email ?? '',
         phone: userCredential.user.phoneNumber ?? '',
         accountVerification: {
           emailVerified: false,
           phoneVerified: false,
           identityVerified: false
         },
         accountCreationDate: serverTimestamp(),
         bio: 'RentSmart platform user',
         communicationPreferences: {
           emailOffers: false,
           pushNotifications: false
         },
         listingPreferences: {
           showEmail: false,
           showMobile: false
         },
         role: 'renter' // Default role for Google sign-up
       };

       await insertDocument('users', userData, userCredential.user.uid);
       navigate('/browse'); // Navigate to browse page after successful signup
     } catch (error) {
       setError('Failed to sign up with Google. Please try again.');
       console.error(error);
     } finally {
       setIsLoading(false);
     }
   };

  useEffect(() => {
    if (currentUser) {
      navigate('/dashboard');
    }
  }, [currentUser, navigate]);

  return (
    <div className="w-full max-w-md mx-auto mt-8">
      <div className="bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-4">
        <div className="text-center mb-6">
          <UserPlus className="h-12 w-12 text-blue-500 mx-auto mb-2" />
          <h2 className="text-3xl font-bold mb-2 text-gray-800">
            Create your account
          </h2>
          <p className="text-center text-gray-600">
            It only takes 2 minutes to get started
          </p>
        </div>

        {/* Google Sign Up Button */}
        <div className="space-y-4 mb-6">
          <button
            onClick={handleGoogleSignUp}
            disabled={isLoading}
            className="w-full flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <img className="h-5 w-5 mr-2" src={googleLogoSvg} alt="Google" />
            Continue with Google
          </button>

          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-gray-500">Or</span>
            </div>
          </div>
        </div>

        <div className="mb-4">
          <div className="flex justify-between">
            <div
              className={`w-1/2 h-1 ${
                step === 1 ? 'bg-blue-500' : 'bg-gray-200'
              } rounded-full transition-all duration-300`}
            ></div>
            <div
              className={`w-1/2 h-1 ${
                step === 2 ? 'bg-blue-500' : 'bg-gray-200'
              } rounded-full transition-all duration-300`}
            ></div>
          </div>
        </div>
        <form onSubmit={step === 1 ? handleNextStep : createUser}>
          {error && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
              role="alert"
            >
              <span className="block sm:inline">{error}</span>
            </div>
          )}
          {step === 1 && (
            <>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-semibold mb-2"
                  htmlFor="email"
                >
                  Email address
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email address"
                  className="shadow-sm appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  value={signUpData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-semibold mb-2"
                  htmlFor="password"
                >
                  Password
                </label>
                <div className="relative">
                  <input
                    id="password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="••••••"
                    className="shadow-sm appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    value={signUpData.password}
                    onChange={handleInputChange}
                    required
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 flex items-center pr-3"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <EyeSlashIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    ) : (
                      <EyeIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    )}
                  </button>
                </div>
                <ul className="text-xs mt-2 space-y-1">
                  {passwordRequirements.map((requirement) => {
                    const isValid = checkPasswordRequirement(
                      requirement,
                      signUpData.password
                    );
                    return (
                      <li
                        key={requirement.id}
                        className={`flex items-center space-x-2 ${
                          signUpData.password
                            ? isValid
                              ? 'text-green-600'
                              : 'text-red-500'
                            : 'text-gray-500'
                        }`}
                      >
                        {signUpData.password && (
                          <span>
                            {isValid ? (
                              <svg
                                className="w-4 h-4 text-green-500"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path d="M5 13l4 4L19 7"></path>
                              </svg>
                            ) : (
                              <svg
                                className="w-4 h-4 text-red-500"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path d="M6 18L18 6M6 6l12 12"></path>
                              </svg>
                            )}
                          </span>
                        )}
                        <span>{requirement.label}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-semibold mb-2"
                  htmlFor="confirmPassword"
                >
                  Confirm Password
                </label>
                <div className="relative">
                  <input
                    id="confirmPassword"
                    name="confirmPassword"
                    type={showConfirmPassword ? 'text' : 'password'}
                    placeholder="••••••"
                    className="shadow-sm appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    value={signUpData.confirmPassword}
                    onChange={handleInputChange}
                    required
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 flex items-center pr-3"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? (
                      <EyeSlashIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    ) : (
                      <EyeIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    )}
                  </button>
                </div>
              </div>
            </>
          )}
          {step === 2 && (
            <>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-semibold mb-2"
                  htmlFor="name"
                >
                  Full Name
                </label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Full Name"
                  className="shadow-sm appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  value={signUpData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-semibold mb-2"
                  htmlFor="phone"
                >
                  Phone Number
                </label>
                <div className="relative">
                  <PhoneInput
                    country={'ie'}
                    value={signUpData.phone}
                    onChange={(phone) =>
                      setSignUpData((prev) => ({ ...prev, phone }))
                    }
                    inputProps={{
                      name: 'phone',
                      required: true,
                      className:
                        'w-full pl-[4.5rem] pr-3 py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent'
                    }}
                    containerClass="w-full"
                    buttonClass="absolute left-0 top-0 bottom-0 flex items-center justify-center px-3 border-r border-gray-300"
                    dropdownClass="absolute w-full z-10"
                  />
                </div>
              </div>
              <div className="mb-6">
                <label className="block text-gray-700 text-sm font-semibold mb-2">
                  What would you like to do?*
                </label>
                <div className="flex flex-col space-y-2">
                  <label className="inline-flex items-center p-3 border rounded-md hover:bg-gray-50 cursor-pointer">
                    <input
                      type="radio"
                      name="role"
                      value="renter"
                      checked={signUpData.role === 'renter'}
                      onChange={handleInputChange}
                      className="form-radio text-blue-500"
                      required
                    />
                    <span className="ml-2">I want to rent a property</span>
                  </label>
                  <label className="inline-flex items-center p-3 border rounded-md hover:bg-gray-50 cursor-pointer">
                    <input
                      type="radio"
                      name="role"
                      value="landlord"
                      checked={signUpData.role === 'landlord'}
                      onChange={handleInputChange}
                      className="form-radio text-blue-500"
                      required
                    />
                    <span className="ml-2">I want to list a property</span>
                  </label>
                </div>
              </div>
              <div className="mb-6">
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={agreeToTerms}
                    onChange={(e) => setAgreeToTerms(e.target.checked)}
                    className="form-checkbox text-blue-500"
                    required
                  />
                  <span className="ml-2 text-sm text-gray-700">
                    I agree to the{' '}
                    <Link
                      to="/terms-of-service"
                      className="text-blue-500 hover:underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms of Service
                    </Link>
                  </span>
                </label>
              </div>
            </>
          )}
          <div className="flex items-center justify-between">
            {step === 2 && (
              <button
                type="button"
                onClick={handlePrevStep}
                className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline"
              >
                Back
              </button>
            )}
            <button
              className={`${
                step === 1 ? 'w-full' : 'w-1/2 ml-2'
              } bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline flex items-center justify-center`}
              type={step === 1 ? 'button' : 'submit'}
              onClick={step === 1 ? handleNextStep : undefined}
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 024 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Creating Account...
                </>
              ) : step === 1 ? (
                'Next'
              ) : (
                'Create Account'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
