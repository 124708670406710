import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signOutUser, useAuth } from '../../auth/firebaseAuth';
import {
  User,
  Menu,
  X,
  ChevronDown,
  ChevronUp,
  LogOut,
  LogIn,
  Home,
  Info,
  UserPlus,
  Grid,
  Bookmark,
  MessageCircle,
  Lightbulb,
  Search,
  HomeIcon
} from 'lucide-react'; // Lucide icons
import appLogo from '../../media/logo/main_logo_only_transparent.png';
import { isAgentOrisLandlord, isRenter } from '../../types/userTypes';

function NavBar() {
  const { currentUser, userProfile } = useAuth();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
  const [isPropertyToolsMenuOpen, setIsPropertyToolsMenuOpen] = useState(false);

  const accountMenuRef = useRef<HTMLDivElement>(null);
  const propertyToolsMenuRef = useRef<HTMLDivElement>(null);

  const isUserRenter = isRenter(userProfile);
  const isUserAgentOrLandlord = isAgentOrisLandlord(userProfile);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        accountMenuRef.current &&
        !accountMenuRef.current.contains(event.target as Node)
      ) {
        setIsAccountMenuOpen(false);
      }
      if (
        propertyToolsMenuRef.current &&
        !propertyToolsMenuRef.current.contains(event.target as Node)
      ) {
        setIsPropertyToolsMenuOpen(false);
      }
    };

    if (isAccountMenuOpen || isPropertyToolsMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isAccountMenuOpen, isPropertyToolsMenuOpen]);

  return (
    <nav className="bg-blue-600 shadow-lg w-full z-50" role="navigation">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16 items-center">
          {/* Logo Section */}
          <div className="flex items-center">
            <Link to="/" className="flex items-center">
              <img
                src={appLogo}
                alt="RentSmart.ie Logo"
                className="relative w-12 h-12 object-contain transform group-hover:scale-105 transition-transform duration-500"
              />
              <span className="text-2xl font-bold text-white ml-2">
                RentSmart.ie
              </span>
            </Link>
          </div>

          {/* Desktop Menu */}
          <div className="hidden sm:flex sm:items-center">
            <button
              onClick={() => navigate('/browse')}
              className="flex items-center text-white hover:text-blue-200 px-3 py-2 rounded-md text-sm font-medium transition duration-300 focus:outline-none"
            >
              <Search className="h-5 w-5 mr-1" />
              Find your home
            </button>

            <button
              onClick={() => navigate('/list-your-property')}
              className="flex items-center text-white hover:text-blue-200 px-3 py-2 rounded-md text-sm font-medium transition duration-300 focus:outline-none"
            >
              <HomeIcon className="h-5 w-5 mr-1" />
              List your property for free
            </button>

            <button
              onClick={() => navigate('/property-tools')}
              className="flex items-center text-white hover:text-blue-200 px-3 py-2 rounded-md text-sm font-medium transition duration-300 focus:outline-none"
            >
              <Lightbulb className="h-5 w-5 mr-2" />
              Discover property Tools
            </button>

            {/* Account Links */}
            {currentUser ? (
              <div className="ml-6 relative" ref={accountMenuRef}>
                <button
                  onClick={() => setIsAccountMenuOpen(!isAccountMenuOpen)}
                  className="flex items-center text-white hover:text-blue-200 px-3 py-2 rounded-md text-sm font-medium transition duration-300 focus:outline-none"
                  aria-haspopup="true"
                  aria-expanded={isAccountMenuOpen}
                >
                  <User className="h-5 w-5 mr-1" />
                  <span className="ml-2 text-sm">
                    {currentUser.displayName || 'Account'}
                  </span>
                  {isAccountMenuOpen ? (
                    <ChevronUp className="h-4 w-4 ml-1" />
                  ) : (
                    <ChevronDown className="h-4 w-4 ml-1" />
                  )}
                </button>
                {isAccountMenuOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-2 z-50 text-sm">
                    <Link
                      to="/profile"
                      className="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100"
                      onClick={() => setIsAccountMenuOpen(false)}
                    >
                      <User className="h-5 w-5 mr-2 text-gray-500" />
                      Profile
                    </Link>
                    {isUserAgentOrLandlord && (
                      <Link
                        to="/dashboard"
                        className="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100"
                        onClick={() => setIsAccountMenuOpen(false)}
                      >
                        <Grid className="h-5 w-5 mr-2 text-gray-500" />
                        Listing Dashboard
                      </Link>
                    )}
                    <Link
                      to="/messages"
                      className="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100"
                      onClick={() => setIsAccountMenuOpen(false)}
                    >
                      <MessageCircle className="h-5 w-5 mr-2 text-gray-500" />
                      Messages
                    </Link>
                    {isUserAgentOrLandlord && (
                      <Link
                        to="/my-listings"
                        className="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100"
                        onClick={() => setIsAccountMenuOpen(false)}
                      >
                        <Home className="h-5 w-5 mr-2 text-gray-500" />
                        My Listings
                      </Link>
                    )}
                    {isUserRenter && (
                      <Link
                        to="/bookmarks"
                        className="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100"
                        onClick={() => setIsAccountMenuOpen(false)}
                      >
                        <Bookmark className="h-5 w-5 mr-2 text-gray-500" />
                        Bookmarks
                      </Link>
                    )}
                    <Link
                      to="/about-us"
                      className="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100"
                      onClick={() => setIsAccountMenuOpen(false)}
                    >
                      <Info className="h-5 w-5 mr-2 text-gray-500" />
                      About us
                    </Link>
                    <button
                      onClick={() => {
                        signOutUser();
                        navigate('/');
                        setIsAccountMenuOpen(false);
                      }}
                      className="flex items-center w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                    >
                      <LogOut className="h-5 w-5 mr-2 text-gray-500" />
                      Sign Out
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <>
                <button
                  onClick={() => navigate('/login')}
                  className="flex items-center text-white hover:text-blue-200 px-3 py-2 rounded-md text-sm font-medium transition duration-300 focus:outline-none"
                >
                  <LogIn className="h-5 w-5 mr-2" />
                  Sign In
                </button>
                <button
                  onClick={() => navigate('/signup')}
                  className="flex items-center text-white hover:text-blue-200 px-3 py-2 rounded-md text-sm font-medium transition duration-300 focus:outline-none"
                >
                  <UserPlus className="h-5 w-5 mr-2" />
                  Create your account
                </button>
              </>
            )}
          </div>

          {/* Mobile Menu Button */}
          <div className="sm:hidden flex items-center">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-white hover:text-blue-200 focus:outline-none"
            >
              {isOpen ? (
                <X className="h-6 w-6" aria-hidden="true" />
              ) : (
                <Menu className="h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="sm:hidden">
          <div className="pt-2 pb-4 space-y-1 bg-blue-600">
            <button
              onClick={() => {
                navigate('/browse');
                setIsOpen(false);
              }}
              className="flex items-center px-4 py-2 text-white hover:bg-blue-700 text-sm"
            >
              <Search className="h-5 w-5 mr-2" aria-hidden="true" />
              Find your home
            </button>

            <button
              onClick={() => {
                navigate('/list-your-property');
                setIsOpen(false);
              }}
              className="flex items-center px-4 py-2 text-white hover:bg-blue-700 text-sm"
            >
              <HomeIcon className="h-5 w-5 mr-2" />
              List your property for free
            </button>

            <button
              onClick={() => {
                navigate('/property-tools');
                setIsOpen(false);
                setIsPropertyToolsMenuOpen(false); // Close dropdown
              }}
              className="flex items-center px-4 py-2 text-white hover:bg-blue-700 text-sm"
            >
              <Lightbulb className="h-5 w-5 mr-1" aria-hidden="true" />
              Discover property tools
            </button>

            {/* Account Links for Mobile */}
            {currentUser ? (
              <>
                <Link
                  to="/profile"
                  className="flex items-center px-4 py-2 text-white hover:bg-blue-700 text-sm"
                  onClick={() => setIsOpen(false)}
                >
                  <User className="h-5 w-5 mr-2" aria-hidden="true" />
                  Profile
                </Link>
                {isUserAgentOrLandlord && (
                  <Link
                    to="/dashboard"
                    className="flex items-center px-4 py-2 text-white hover:bg-blue-700 text-sm"
                    onClick={() => setIsOpen(false)}
                  >
                    <Grid className="h-5 w-5 mr-2" aria-hidden="true" />
                    Listing Dashboard
                  </Link>
                )}
                {isUserRenter && (
                  <Link
                    to="/bookmarks"
                    className="flex items-center px-4 py-2 text-white hover:bg-blue-700 text-sm"
                    onClick={() => setIsOpen(false)}
                  >
                    <Bookmark className="h-5 w-5 mr-2" aria-hidden="true" />
                    Bookmarks
                  </Link>
                )}
                <button
                  onClick={() => {
                    signOutUser();
                    navigate('/');
                    setIsOpen(false);
                  }}
                  className="flex items-center w-full text-left px-4 py-2 text-white hover:bg-blue-700 text-sm"
                >
                  <LogOut className="h-5 w-5 mr-2" aria-hidden="true" />
                  Sign Out
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={() => {
                    navigate('/login');
                    setIsOpen(false);
                  }}
                  className="flex items-center px-4 py-2 text-white hover:bg-blue-700 text-sm"
                >
                  <LogIn className="h-5 w-5 mr-2" aria-hidden="true" />
                  Sign In
                </button>

                <button
                  onClick={() => {
                    navigate('/signup');
                    setIsOpen(false);
                  }}
                  className="flex items-center px-4 py-2 text-white hover:bg-blue-700 text-sm"
                >
                  <UserPlus className="h-5 w-5 mr-2" aria-hidden="true" />
                  Create your account
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </nav>
  );
}

export default NavBar;
